import React from 'react'

import styles from './hero.module.scss'

const Hero = () => (
  <div className={styles.hero}>
    <div className={styles.background} />
    <div className={styles.container}>
      <div className={styles.strap}>
        We make mobile apps that excite and engage.
      </div>
    </div>
  </div>
)

export default Hero
