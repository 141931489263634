import React, { Component } from 'react'

import styles from './contact.module.scss'
import helpers from '../styles/helpers.module.scss'

const STATE_READY = 'STATE_READY'
const STATE_SENDING = 'STATE_SENDING'
const STATE_ERROR = 'STATE_ERROR'
const STATE_SENT = 'STATE_SENT'

export default class Contact extends Component {
  state = {
    state: STATE_READY,
    name: '',
    company: '',
    email: '',
    message: '',
  }

  _reset = () => {
    this.setState({ state: STATE_READY })
  }

  _submit = () => {
    if (this.state.state !== STATE_READY) {
      return
    }

    if (this.state.name.length === 0 || this.state.email.length === 0 || this.state.message === 0) {
      return;
    }

    this.setState({ sending: true })

    const data = {
      name: this.state.name,
      company: this.state.company,
      email: this.state.email,
      message: this.state.message,
    }

    fetch('/contact', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    })
      .then(() => {
        this.setState({ state: STATE_SENT })
      })
      .catch(() => {
        this.setState({ state: STATE_ERROR })
      })
  }

  _renderForm() {
    return (
      <div className={styles.contact}>
        <div className={styles.row}>
          <label htmlFor="hire_name">Name*</label>
          <input
            type="text"
            id="hire_name"
            name="name"
            placeholder="Name*"
            required
            onChange={e => this.setState({ name: e.target.value })}
            value={this.state.name}
          />

          <label htmlFor="hire_comapny">Company*</label>
          <input
            type="text"
            id="hire_comapny"
            name="company"
            placeholder="Company"
            onChange={e => this.setState({ company: e.target.value })}
            value={this.state.company}
          />

          <label htmlFor="hire_email">Email*</label>
          <input
            type="email"
            id="hire_email"
            name="email"
            placeholder="Email*"
            required
            onChange={e => this.setState({ email: e.target.value })}
            value={this.state.email}
          />
        </div>

        <div className={styles.row}>
          <label htmlFor="hire_message">Message</label>
          <textarea
            name="message"
            id="hire_message"
            placeholder="Message*"
            rows="4"
            required
            onChange={e => this.setState({ message: e.target.value })}
            value={this.state.message}
          />
        </div>

        <div className={styles.row}>
          <button
            type="submit"
            className={helpers.button}
            onClick={this._submit}
            disabled={this.state.state === STATE_SENDING}
          >
            {this.state.state === STATE_SENDING ? 'Sending...' : 'Send message' }
          </button>
        </div>
      </div>
    )
  }

  _renderError() {
    return (
      <div className={styles.contact}>
        <div className={styles.col}>
          <p>Sorry but there was an error sending your message.</p>
          <p>We're not sure what happened :( but you can try again.</p>
          <button className={helpers.button} onClick={this._reset}>Try Again</button>
        </div>
      </div>
    )
  }

  _renderSent() {
    return (
      <div className={styles.contact}>
        <div className={styles.col}>
          <h2>Your message has been sent</h2>
          <p>Thank you for your message, one of the team will reply to your message shortly.</p>
          <button className={helpers.button} onClick={this._reset}>Send another</button>
        </div>
      </div>
    )
  }

  render() {
    switch (this.state.state) {
      case STATE_SENT:
        return this._renderSent()
      case STATE_ERROR:
        return this._renderError()
      case STATE_SENDING:
      case STATE_READY:
        return this._renderForm()
      default:
        return this._renderForm()
    }
  }
}
