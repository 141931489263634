import React from 'react'
import PropTypes from 'prop-types'

import styles from './section.module.scss'

const Section = ({ children, className, title, background, style, anchor }) => (
  <section
    style={{ backgroundColor: background ? background : '#000000', ...style }}
    className={[styles.section, className].join(' ')}
    id={anchor}
  >
    <header>
      <h1>{title}</h1>
    </header>

    {children}
  </section>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Section
