import React from 'react'

import Layout from '../components/layout'
import Hero from '../components/hero'
import Section from '../components/section'
import Contact from '../components/contact'
import Locations from '../components/locations'
import Services from '../components/services'
import Clients from '../components/clients'

const IndexPage = () => (
  <Layout>
    <Hero />

    <Section title="Our Services" background={`#191828`}>
      <Services />
    </Section>

    <Section
      title="Our Locations"
      background={`#181722`}
      style={{ paddingBottom: 0 }}
    >
      <Locations />
    </Section>

    <Section title="Get in Touch" anchor="contact" background={`#191828`}>
      <Contact />
    </Section>

    <Section title="Trusted to Deliver" background={`#181722`}>
      <Clients />
    </Section>
  </Layout>
)

export default IndexPage
