import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import styles from './services.module.scss'
import helpers from '../styles/helpers.module.scss'

import DesignIcon from '../images/design-icon.svg'
import AppsIcon from '../images/apps-icon.svg'
import CodingIcon from '../images/coding-icon.svg'
import ProjectIcon from '../images/project-icon.svg'

const Services = () => (
  <div className={styles.services}>
    <p>
      Our team is dedicated to simplifying the processes, passions and lives of
      our clients through time saving innovations and services.
    </p>

    <div className={styles.row}>
      <div className={styles.service}>
        <img src={DesignIcon} alt="Design icon" />
        <h2>Design</h2>
        <p>Understanding your audience to build beautiful and intuitive user-centric design.</p>
        <figcaption>design by Smalllike from the Noun Project</figcaption>
        <AnchorLink className={helpers.button} href={`#contact`}>
          Learn more
        </AnchorLink>
      </div>

      <div className={styles.service}>
        <img src={AppsIcon} alt="Mobile apps icon" />
        <h2>Mobile Apps</h2>
        <p>Innovative and technically brilliant digital experiences that your customers will love.</p>
        <figcaption>Apps by Aneeque Ahmed from the Noun Project</figcaption>
        <AnchorLink className={helpers.button} href={`#contact`}>
          Learn more
        </AnchorLink>
      </div>

      <div className={styles.service}>
        <img src={CodingIcon} alt="Websites icon" />
        <h2>Websites</h2>
        <p>Creative, functional and highly engaging websites to help your business succeed.</p>
        <figcaption>coding by Iconstock from the Noun Project</figcaption>
        <AnchorLink className={helpers.button} href={`#contact`}>
          Learn more
        </AnchorLink>
      </div>

      <div className={styles.service}>
        <img src={ProjectIcon} alt="Project management icon" />
        <h2>Project Management</h2>
        <p>Building your products on budget, in time and beyond your expectations.</p>
        <figcaption>Project by Nithinan Tatah from the Noun Project</figcaption>
        <AnchorLink className={helpers.button} href={`#contact`}>
          Learn more
        </AnchorLink>
      </div>
    </div>
  </div>
)

export default Services
