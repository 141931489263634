import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import styles from './locations.module.scss'
import helpers from '../styles/helpers.module.scss'

import { ReactComponent as Map } from '../images/map.svg'

const Locations = () => (
  <div className={styles.locations}>
    <div className={styles.map}>
      <Map />
    </div>
    <div className={styles.offices}>
      <ul>
        <li>
          <h2>Hampshire</h2>
          <p>
            Manor Farm
            <br />
            Manor Road
            <br />
            Hayling Island
            <br />
            Hampshire
            <br />
            PO11 0QW
          </p>
        </li>
        <li>
          <h2>West Sussex</h2>
          <p>
            Blue Bamboo Studios Limited
            <br />
            Broadbridge Heath
            <br />
            Horsham
            <br />
            West Sussex
            <br />
            RH12 3TY
          </p>
        </li>
        <li>
          <h2>Say Hello</h2>
          <p>hello@bluebamboostudios.com</p>
          <hr />
          <AnchorLink className={helpers.button} href={`#contact`}>
            Say hello
          </AnchorLink>
        </li>
      </ul>
    </div>
  </div>
)

export default Locations
