import React from 'react'

import JackMortonLogo from '../images/jackmorton.svg'
import VoxitLogo from '../images/voxit.svg'
import CiscoLogo from '../images/cisco.svg'

import styles from './clients.module.scss'

const Clients = () => (
  <div className={styles.clients}>
    <div className={styles.client}>
      <a href="http://www.jackmorton.com">
        <img
          src={JackMortonLogo}
          alt="Jack Morton Worldwide: Global Brand Experience Agency"
        />
      </a>
    </div>

    <div className={styles.client}>
      <a href="https://www.voxit.co.uk/">
        <img src={VoxitLogo} alt="VOXIT - IT with Personality" />
      </a>
    </div>

    <div className={styles.client}>
      <a href="https://www.cisco.com/c/en_uk/index.html">
        <img src={CiscoLogo} alt="Cisco – UK & Ireland" />
      </a>
    </div>
  </div>
)

export default Clients
